'use client';

import React from 'react';

import { Flex, Heading, Stack, Text } from '@backyard-ui/core';

import { type Content } from '@/infra/services/categories/get-details';
import { Image } from '@/presenters/components/common/Image';
import { Wishlist } from '@/presenters/components/common/Wishlist';

import { WishlistButton } from '../WishlistButton';

import { styles } from './ContentCard.styles';

type ContentCardProps = Content & {
  isInCarousel?: boolean;
};

function ContentCard(props: ContentCardProps) {
  const { id, image, title, publishDate, url, isInCarousel = false } = props;
  const classNames = styles({ isInCarousel });

  return (
    <div className={classNames.base()}>
      <div className={classNames.imageWrapper()}>
        <a href={url}>
          <Image
            src={image.url}
            alt={image.alt}
            width={image.size.width}
            height={image.size.height}
            className={classNames.image()}
          />
        </a>
      </div>

      <Stack direction="column" spacing={2} className={classNames.content()}>
        <a href={url}>
          <Heading color="neutral-900" noOfLines={2} size="md" asChild>
            <h4>{title}</h4>
          </Heading>
        </a>

        <Flex justify="space-between" align="end">
          <Text align="left" color="gray-400" size="md" asChild>
            <span>{new Date(publishDate).toLocaleDateString('pt-BR')}</span>
          </Text>

          <Wishlist item={{ id, type: 'contents' }} trigger={WishlistButton} />
        </Flex>
      </Stack>
    </div>
  );
}

export default ContentCard;
