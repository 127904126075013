import { tv } from 'tailwind-variants';

export const styles = tv({
  base: `
    relative
    flex
    items-center
    justify-center
    rounded-full
    border-solid

    [&>img]:block
    [&>img]:h-full
    [&>img]:w-full
    [&>img]:rounded-full

    [&>svg]:block
    [&>svg]:h-[60%]
    [&>svg]:w-[60%]

  `,
  slots: {
    pictureIcon: `
      absolute
      bottom-0
      left-0
      flex
      items-center
      justify-center
      rounded-full
      border-solid
      transition
      duration-300
      ease-in
    `,
  },

  variants: {
    isActive: {
      true: {
        base: `
          border-violet-500

          [&>svg]:fill-white
          [&>svg]:text-white
        `,
        pictureIcon: `
          border-violet-500
          bg-violet-500

          [&>svg]:text-white
        `,
      },
      false: {
        base: `
          border-neutral-100

          [&>svg]:fill-violet-700
          [&>svg]:text-violet-700
        `,
        pictureIcon: `
          border-neutral-100
          bg-white

          [&>svg]:text-neutral-300
        `,
      },
    },
    placement: {
      thumb: {
        base: `
          h-[84px]
          w-[84px]
          border-[3px]
        `,
        pictureIcon: `
          h-7
          w-7
          border-[3px]

          [&>svg]:h-3
          [&>svg]:w-3
        `,
      },
      modal: {
        base: `
          h-16
          w-16
          border-2
          md:h-[84px]
          md:w-[84px]
          md:border-[3px]
        `,
        pictureIcon: `
          h-5
          w-5
          border-2
          md:h-7
          md:w-7
          md:border-[3px]

          [&>svg]:h-2.5
          [&>svg]:w-2.5
          md:[&>svg]:h-3
          md:[&>svg]:w-3
        `,
      },
    },
  },
});
