import { type ComponentType } from 'react';

import { FirstStep, SecondStep, ThirdStep } from '../';
import { type VoucherFullData } from '../../../model';
import { useVoucherModalStore } from '../../store';

const STEPS_COMPONENTS: Record<
  number,
  ComponentType<{ voucherId: VoucherFullData['id'] }>
> = {
  1: FirstStep,
  2: SecondStep,
  3: ThirdStep,
};

interface ShareModalProps {
  voucherId: VoucherFullData['id'];
}

function ShareModal(props: Readonly<ShareModalProps>) {
  const { step } = useVoucherModalStore();

  const Step = STEPS_COMPONENTS[step];

  return <Step voucherId={props.voucherId} />;
}

export default ShareModal;
