import { useState, type CSSProperties } from 'react';

import dynamic from 'next/dynamic';

import { useMediaQuery } from '@backyard-ui/hooks';

import { type ModularContent } from '@/infra/services/modular-content/model';
import { GtmDataAttributesGenerator } from '@/infra/tracking/gtm-data-attributes';
import * as tracker from '@/infra/tracking/tracker';
import { Carousel } from '@/presenters/components/common/Carousel';
import { Image } from '@/presenters/components/common/Image';
import { useRunOnce } from '@/presenters/hooks/use-run-once';
import { useUrl } from '@/presenters/hooks/use-url';
import { useGlobalStore } from '@/presenters/store/global';

import { styles } from './Slide.styles';

const Countdown = dynamic(
  () => import('../Countdown').then((mod) => mod.Countdown),
  {
    ssr: false,
  }
);

export interface SlideProps extends ModularContent.Slides {}

function Slide(props: SlideProps) {
  const { items, title: moduleTitle, type } = props;

  const [slideIndex, setSlideIndex] = useState(0);

  const region = useGlobalStore((state) => state.user.session.region);

  const location = useUrl();

  const isDesktop = useMediaQuery('md');

  const dynamicStyles = {
    '--slide-color-start': items[slideIndex].image.colors?.left,
    '--slide-color-end': items[slideIndex].image.colors?.right,
  } as CSSProperties;

  const classNames = styles();

  useRunOnce(() => {
    tracker.onBannerView({
      items,
      moduleType: type,
      region,
    });
  }, Boolean(items.length) && Boolean(region));

  return (
    <Carousel
      controls={{
        arrows: true,
        dots: true,
      }}
      onSlideChange={(index) => setSlideIndex(index)}
      options={{ loop: true, duration: 30 }}
      autoPlay={{
        delay: 5000,
        stopOnMouseEnter: true,
        stopOnInteraction: false,
      }}
      className={classNames.carousel()}
      style={dynamicStyles}
    >
      {items?.map((item, index) => {
        const { image, url, countdown, title, elementType } = item;
        const imageData = isDesktop ? image.desktop : image.mobile;

        if (!imageData) {
          return null;
        }

        return (
          <div
            key={index}
            className={classNames.base()}
            data-testid="slide"
            {...GtmDataAttributesGenerator.banner({
              elementType,
              type,
              name: title,
              index: index,
              listName: moduleTitle ?? '',
            })}
          >
            {countdown?.end && (
              <div className={classNames.countdown()}>
                <Countdown endDate={new Date(countdown?.end)} />
              </div>
            )}
            <a
              data-testid="slide-anchor"
              href={url ?? undefined}
              onClick={(event) => {
                event.preventDefault();

                tracker.onBannerClick({
                  url: url ?? '',
                  imageAlt: image.alt,
                  isMobile: !isDesktop,
                  position: index + 1,
                  moduleType: type,
                  location: location,
                  title,
                });

                if (url) {
                  window.location.href = url;
                }
              }}
            >
              <Image
                src={imageData.url}
                alt={image.alt}
                width={imageData.size.width}
                height={imageData.size.height}
                priority={index === 0}
              />
            </a>
          </div>
        );
      })}
    </Carousel>
  );
}

export default Slide;
