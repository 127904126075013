import {
  type Recommendations,
  type Placement,
} from '@/infra/services/rich-relevance/model';

export function parsePlacementsData(placements: Placement[]) {
  return placements.reduce<Recommendations>(
    (acc, result) => ({
      ...acc,
      [result.placement]: {
        products: result.recommendedProducts,
        title: result.strategyMessage,
      },
    }),
    {}
  );
}
