import { Avatar, RadioGroup, Spinner, Stack, Text } from '@backyard-ui/core';

import { useGlobalStore } from '@/presenters/store/global';

import { useGetCustomers } from '../../../hooks/useGetCustomers';
import { useVoucherModalStore } from '../../store';

function CustomersList() {
  const userId = useGlobalStore((state) => state.user.resources?.id);
  const { data: customers, isLoading } = useGetCustomers(userId);
  const { selectedCustomer, selectCustomer } = useVoucherModalStore();

  const handleSelectCustomer = (customerId: string) => {
    const customer = customers?.find((customer) => customer.id === customerId);

    selectCustomer(customer);
  };

  if (isLoading) {
    return (
      <div className="flex w-full justify-center">
        <Spinner data-testid="voucher-customers-loading" />
      </div>
    );
  }

  return (
    <Stack direction="column" spacing={6}>
      <RadioGroup.Root
        value={selectedCustomer?.customer.id ?? undefined}
        onValueChange={handleSelectCustomer}
      >
        {customers?.map((customer) => (
          <RadioGroup.Item
            key={customer.customer.id}
            value={customer.customer.id ?? ''}
          >
            <div className="flex cursor-pointer gap-2">
              <Avatar.Root variant="subtle" appearance="neutral">
                <Avatar.Fallback>{customer.customerName[0]}</Avatar.Fallback>
              </Avatar.Root>
              <div className="relative">
                <Text size="xl" color="gray-600" asChild>
                  <span>{customer.customer.name}</span>
                </Text>
                <div className="flex items-center gap-1">
                  <div className="h-2 w-2 rounded-full bg-primary-600" />
                  <Text size="md" color="primary-600" asChild>
                    <span>{customer.projectName}</span>
                  </Text>
                </div>
              </div>
            </div>
          </RadioGroup.Item>
        ))}
      </RadioGroup.Root>
    </Stack>
  );
}

export default CustomersList;
