import { tv } from 'tailwind-variants';

export const styles = tv({
  base: `
    relative
    grid
    h-full
    w-full
    overflow-hidden
    rounded-[inherit]
    [grid-template-areas:'header''info''footer']
    after:absolute
    after:bottom-0
    after:top-0
    after:z-[-1]
    after:hidden
    after:w-1/2
    after:content-['']
    md:[grid-template-areas:'header_info''footer_info']
    md:after:grid
  `,
  variants: {
    isShareable: {
      true: `
        md:[grid-template-columns:200px_1fr]
      `,
      false: `
        md:[grid-template-columns:1fr_1fr]
      `,
    },
    isActive: {
      true: `
       after:bg-loyalty-gradient
      `,
    },
  },
});
