import { useQuery } from '@tanstack/react-query';

import { Services } from '@/infra/services';
import { useGlobalStore } from '@/presenters/store/global';

const useBannersAds = (slug: string) => {
  const {
    user: { resources },
  } = useGlobalStore((state) => state);

  const query = useQuery(
    ['banners-ads', resources?.userTrackingId, slug],
    () =>
      Services.NewTail.getBannersAds({
        context: 'home',
        session_id: resources?.userTrackingId,
        user_id: resources?.id,
      }),
    {
      enabled:
        Boolean(resources?.userTrackingId) &&
        ['home', 'home-app'].includes(slug),
    }
  );

  return query;
};

export default useBannersAds;
