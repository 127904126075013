import { getDepartmentIcon } from '@leroy-merlin-br/backpack-header';

/**
 * Esse util é uma solução temporária, pois a API de vouchers
 * retorna o slug do departamento ao invés do id.
 * O ideal é que o front sempre receba o id, pois o slug pode mudar.
 */
export const DEPARTMENTS_IDS_DICTIONARY = {
  'ar-e-ventilacao': 'f48f3f336dd76e7234e119ce',
  'audio-video-e-tvs': '5cd1ca45f846aa1f58061617',
  banheiros: '94301c9bf86859d0b3ea8a69',
  'cama-mesa-e-banho': '5c87fc0df846aa23bb05e4f7',
  'cozinhas-e-areas-de-servico': 'e315adee7e1ed4ea6e920819',
  decoracao: 'ba87d76c99d8a1523471c0b6',
  eletrodomesticos: '5ba3b5e4c7b7920fd4665686',
  eletros: '5ba3b5e4c7b7920fd4665686',
  eletroportateis: '5b800040ca7b80459f21562f',
  ferragens: '4b114b65dfc74e7228e6750f',
  ferramentas: 'b42110d83574a6a9fbdcbcfb',
  informatica: 'ba33c74862bb71863af38c66',
  iluminacao: '9817332c9cde3a4dfc02372e',
  'jardim-e-lazer': '59668bf188e776c91cf82a21',
  'jardim-e-varanda': '59668bf188e776c91cf82a21',
  'limpeza-da-casa': 'd82c080656ab1649302b06d9',
  'limpeza-e-manutencao': 'd82c080656ab1649302b06d9',
  madeiras: '1a8ad120950bca99adda415c',
  'marcenaria-e-madeiras': '1a8ad120950bca99adda415c',
  'materiais-de-construcao': 'a2e3587143e67e3561d6b90d',
  'materiais-eletricos': 'cd9681d503b6b5b69ce0990e',
  'materiais-hidraulicos': 'fd8728d8714555dec83a3dea',
  moveis: '5ba391add4ee0d0e9163024b',
  organizacao: '31f9564d0e848f47172111bd',
  'organizacao-da-casa': '31f9564d0e848f47172111bd',
  'pet-shop': '5ca652f1f846aa5eaf15618b',
  'pintura-e-acessorios': 'ea20efefee3df13ca161ba64',
  'pisos-e-revestimentos': 'ffbaf27929868c29b913d828',
  'portas-janelas-e-portoes': '66160b2ab4c121cfa29a6268',
  'seguranca-e-comunicacao': 'c0912e548bb7ceff3911b67d',
  'utilidades-domesticas': '5dbc9a5bd07ab1130e527d58',
};

const getDepartmentIdBySlug = (slug: string) => {
  return DEPARTMENTS_IDS_DICTIONARY[
    slug as keyof typeof DEPARTMENTS_IDS_DICTIONARY
  ];
};

export const getDepartmentIconBySlug = (slug: string) => {
  const id = getDepartmentIdBySlug(slug);

  return getDepartmentIcon(id);
};
