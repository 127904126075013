import { type UseMutationOptions, useMutation } from '@tanstack/react-query';

import { Services } from '@/infra/services';
import { type NewsletterError } from '@/infra/services/newsletter/newsletter-error';
import { type Body } from '@/infra/services/newsletter/post-newsletter-subscription';

type Options = UseMutationOptions<void, NewsletterError, Body>;

export const usePostNewsletterSubscription = (options: Options) => {
  return useMutation<void, NewsletterError, Body>({
    mutationFn: Services.Newsletter.postNewsletterSubscription,
    ...options,
  });
};
