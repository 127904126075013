import { type ReactNode } from 'react';

import { useGetVoucherData } from '../../../hooks/useGetVoucherData';
import { type VoucherFullData } from '../../../model';

import { styles } from './Grid.styles';

export interface GridProps {
  voucherId: VoucherFullData['id'];
  children: ReactNode;
}

function Grid(props: Readonly<GridProps>) {
  const { children } = props;

  const { data } = useGetVoucherData(props.voucherId);

  const className = styles({
    isActive: data?.isActive,
    isShareable: data?.isShareable,
  });

  return <div className={className}>{children}</div>;
}

export default Grid;
