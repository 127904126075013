import { useQuery } from '@tanstack/react-query';

import { Services } from '@/infra/services';
import { type PageType } from '@/infra/services/modular-content/get-modules';
import { useVoucherQueryActions } from '@/presenters/components/common/Vouchers/hooks/useVoucherQueryActions';

export interface UseCouponsParams {
  userId: string | null;
  slug: string;
  moduleId: string;
  pageType: PageType;
}

export function useVouchers(params: UseCouponsParams) {
  const { userId, slug, moduleId, pageType } = params;

  const hasLoadedUserData = userId !== null;

  const { createVoucherQuery } = useVoucherQueryActions();

  const query = useQuery(
    ['vouchers', slug, moduleId, userId],

    async () => {
      if (!hasLoadedUserData) return;

      const { data } = await Services.ModularContent.getVouchersData({
        userId,
        slug,
        moduleId,
        pageType,
      });

      data.forEach(createVoucherQuery);

      return data;
    },
    { enabled: hasLoadedUserData }
  );

  return query;
}
