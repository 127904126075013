import { useRef, type MouseEvent } from 'react';

import {
  Text,
  Stack,
  FormControl,
  Label,
  TextField,
  Button,
  toast,
} from '@backyard-ui/core';
import { ShoppingCartOutline } from '@backyard-ui/icons';
import { z } from 'zod';

import { usePostNewsletterSubscription } from './hooks/use-post-newsletter-subscription';

function LeadCapture() {
  const ref = useRef<HTMLInputElement | null>(null);

  const { mutate, isLoading } = usePostNewsletterSubscription({
    onSuccess: () =>
      toast({
        variant: 'solid',
        title: 'Pronto!',
        description: 'E-mail cadastrado com sucesso!',
        isClosable: true,
      }),
    onError: () =>
      toast({
        appearance: 'critical',
        variant: 'solid',
        title: 'Ops!',
        description:
          'Ocorreu alguma instabilidade para cadastrar seu e-mail. Tente novamente mais tarde!',
        isClosable: true,
      }),
  });

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    try {
      const email = z.string().email().parse(ref?.current?.value);

      mutate({ email });
    } catch (error) {
      toast({
        appearance: 'critical',
        variant: 'solid',
        title: 'Erro!',
        description: 'Por favor, insira um e-mail válido',
        isClosable: true,
      });
    }
  };

  return (
    <div className="relative">
      <div className="absolute -right-6 top-2 md:right-[unset] md:top-4">
        <ShoppingCartOutline width={100} color="white" className="opacity-5" />
      </div>

      <Stack direction="column" spacing={2}>
        <div className="w-full rounded-md bg-blue-900 px-10 py-8">
          <Stack
            direction={{
              initial: 'column',
              md: 'row',
            }}
            align={{
              initial: 'flex-start',
              md: 'center',
            }}
          >
            <Text align="left" color="white" size="2xl" weight="bold" asChild>
              <p className="flex flex-wrap gap-1">
                Fique por dentro das{' '}
                <Text color="primary-700" size="2xl" weight="bold" asChild>
                  <span>novidades</span>
                </Text>
                e
                <Text color="primary-700" size="2xl" weight="bold" asChild>
                  <span>promoções</span>
                </Text>
              </p>
            </Text>

            <form className="w-full">
              <FormControl.Root>
                <Label UNSAFE_className="text-white">E-mail</Label>
                <Stack
                  spacing={4}
                  direction={{
                    initial: 'column',
                    md: 'row',
                  }}
                >
                  <TextField.Root UNSAFE_className="grow">
                    <TextField.Input
                      placeholder="seuemail@email.com"
                      ref={ref}
                    />
                  </TextField.Root>
                  <Button isLoading={isLoading} onClick={handleClick}>
                    Cadastrar
                  </Button>
                </Stack>
              </FormControl.Root>
            </form>
          </Stack>
        </div>

        <Text color="neutral-300" size="md" isItalic>
          Ao se cadastrar você concorda em receber e-mails promocionais e
          novidades. Saiba mais na nossa{' '}
          <a href="/institucional/politica-de-privacidade">
            Politica de Privacidade
          </a>
        </Text>
      </Stack>
    </div>
  );
}

export default LeadCapture;
