import { type ReactNode } from 'react';

export interface ButtonGroupProps {
  children: ReactNode;
}

function ButtonGroup(props: Readonly<ButtonGroupProps>) {
  const { children } = props;

  return <div className="flex flex-row gap-2 md:flex-col">{children}</div>;
}

export default ButtonGroup;
