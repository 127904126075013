import { tv } from 'tailwind-variants';

export const styles = tv({
  base: `
    mt-auto
    shadow-md
    [grid-area:footer]
    md:shadow-none
  `,
  variants: {
    isActive: {
      true: `
        bg-loyalty-gradient
        md:bg-none
      `,
      false: `
        px-6
        py-3
        md:py-6
      `,
    },
    isShareable: {
      true: `
        px-6
        py-3
        md:py-6
      `,
    },
  },
});
