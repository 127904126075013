'use client';

import { createContext } from '@backyard-ui/utils';
import { createStore, type StoreApi } from 'zustand';
import { shallow } from 'zustand/shallow';
import { useStoreWithEqualityFn } from 'zustand/traditional';

import { type Recommendations } from '@/infra/services/rich-relevance/model';

type RecommendationsState = {
  isLoading: boolean;
  isError: boolean;
  data?: Recommendations;
};

type ModularContentState = {
  id?: string;
  name: string;
  recommendations: RecommendationsState;
};

export interface ModularContentActions extends ModularContentState {
  setRecommendations: (value: RecommendationsState) => void;
}

export type ModularContentStoreApi = StoreApi<ModularContentActions>;

export const createModularContentStore = (initialState: ModularContentState) =>
  createStore<ModularContentActions>()((set) => ({
    ...initialState,
    setRecommendations: (value) =>
      set((state) => ({
        ...state,
        recommendations: {
          ...value,
        },
      })),
  }));

const { Provider, useContext } = createContext<StoreApi<ModularContentState>>({
  name: '@store/ModularContent',
});

export function useModularContentStore<T>(
  selector: (state: ModularContentState) => T,
  equalityFn: (a: any, b: any) => boolean = shallow
) {
  const context = useContext();

  return useStoreWithEqualityFn(context, selector, equalityFn);
}

export { Provider };
