import { useCallback } from 'react';

import { useQuery } from '@tanstack/react-query';

import { Services } from '@/infra/services';
import { type Customer } from '@/infra/services/vouchers/get-customers';

export function useGetCustomers(userId?: string) {
  return useQuery({
    queryKey: ['customers', userId],
    queryFn: async () => {
      const { data } = await Services.Vouchers.getCustomers();

      return data;
    },
    enabled: Boolean(userId),
    select: useCallback((data: Customer[]) => {
      return data.filter((customer) => customer.status === 'Ativo');
    }, []),
  });
}
