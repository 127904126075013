'use client';

import {
  useCallback,
  useEffect,
  useState,
  type HTMLAttributes,
  type PropsWithChildren,
} from 'react';

import { ChevronLeftOutline, ChevronRightOutline } from '@backyard-ui/icons';
import Autoplay, { type AutoplayOptionsType } from 'embla-carousel-autoplay';
import ClassNames from 'embla-carousel-class-names';
import useEmblaCarousel, { type EmblaOptionsType } from 'embla-carousel-react';

import { carouselStyles, arrowStyles, dotsStyles } from './Carousel.styles';

export interface CarouselProps
  extends HTMLAttributes<HTMLDivElement>,
    PropsWithChildren {
  /**
   * Enable/disabled arrows and dots
   */
  controls?: {
    arrows?: boolean;
    dots?: boolean;
  };
  /**
   * Autoplay options
   */
  autoPlay?: AutoplayOptionsType | boolean;

  /**
   * Embla options
   */
  options?: EmblaOptionsType;

  /**
   *
   */
  onSlideChange?: (index: number) => void;
}

function Carousel(props: CarouselProps) {
  const {
    children,
    options,
    controls,
    className,
    onSlideChange,
    autoPlay,
    ...rest
  } = props;

  const carouselClassNames = carouselStyles();
  const dotsClassNames = dotsStyles();

  const [viewportRef, embla] = useEmblaCarousel(
    {
      ...options,
      inViewThreshold: 1,
    },
    [
      ClassNames({
        dragging: carouselClassNames.dragging(),
        draggable: carouselClassNames.draggable(),
      }),
      ...(autoPlay
        ? [Autoplay(typeof autoPlay === 'boolean' ? {} : autoPlay)]
        : []),
    ]
  );

  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);

  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);

  const onSelect = useCallback(() => {
    if (!embla) {
      return;
    }

    onSlideChange?.(embla.selectedScrollSnap());
    setCurrentIndex(embla.selectedScrollSnap());
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla, onSlideChange]);

  const scrollTo = useCallback(
    (index: number) => embla && embla.scrollTo(index),
    [embla]
  );

  useEffect(() => {
    if (!embla) {
      return;
    }

    onSelect();
    setScrollSnaps(embla.scrollSnapList());

    embla.on('select', onSelect);
  }, [embla, onSelect]);

  return (
    <div
      className={carouselClassNames.base({ className })}
      {...rest}
      data-testid="carousel-module"
    >
      <div className={carouselClassNames.container()}>
        <div className={carouselClassNames.viewport()} ref={viewportRef}>
          <div className={carouselClassNames.slides()}>{children}</div>
        </div>

        {controls?.arrows && (
          <>
            <button
              aria-label="Slide anterior"
              disabled={!prevBtnEnabled}
              onClick={scrollPrev}
              data-testid="Carousel-control-prev"
              className={arrowStyles({ position: 'left' })}
            >
              <ChevronLeftOutline />
            </button>
            <button
              aria-label="Próximo slide"
              disabled={!nextBtnEnabled}
              onClick={scrollNext}
              data-testid="Carousel-control-next"
              className={arrowStyles({ position: 'right' })}
            >
              <ChevronRightOutline />
            </button>
          </>
        )}

        {controls?.dots && (
          <div
            className={dotsClassNames.base()}
            data-testid="Carousel-control-dots"
          >
            {scrollSnaps.map((_, index) => {
              const isSelected = index === currentIndex;

              return (
                <button
                  key={index}
                  onClick={() => scrollTo(index)}
                  aria-label={
                    isSelected
                      ? `Você está no slide ${index + 1} de ${
                          scrollSnaps.length
                        }`
                      : `Navegar para o slide ${index + 1}`
                  }
                  data-selected={isSelected}
                  className={dotsClassNames.item()}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

export default Carousel;
