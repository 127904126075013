import { Stack, Text } from '@backyard-ui/core';
import { ClockOutline } from '@backyard-ui/icons';

import { useGetVoucherData } from '../../hooks/useGetVoucherData';
import { type VoucherFullData } from '../../model';
import { formatter } from '../../utils/formatter';

import { styles } from './FooterText.styles';

export interface FooterTextProps {
  voucherId: VoucherFullData['id'];
  alwaysShowExpiration?: boolean;
}

function FooterText(props: Readonly<FooterTextProps>) {
  const { voucherId, alwaysShowExpiration } = props;

  const { data } = useGetVoucherData(voucherId);

  if (!data) return null;

  const { isActive, type, remainingDays } = data;
  const classNames = styles({ isActive });

  if (type === 'percentage' || isActive || alwaysShowExpiration) {
    return (
      <Stack align="center" spacing="1" justify="center">
        <div className={classNames.icon()}>
          <ClockOutline />
        </div>
        <div className={classNames.base()}>
          <Text size="sm" align="center" color="current">
            {formatter.remainingCouponDays(remainingDays)}
          </Text>
        </div>
      </Stack>
    );
  }

  return (
    <div className={classNames.base()}>
      <Text size="sm" align="center" color="current">
        Troque por pontos
      </Text>
    </div>
  );
}

export default FooterText;
