'use client';

import { useInView } from 'react-intersection-observer';

import { ProductsCarousel } from '@/presenters/components/modular-content/components';
import { useRecommendedProductsData } from '@/presenters/hooks/use-recommended-products-data';
import { useRunOnce } from '@/presenters/hooks/use-run-once';

import { useProductsAds } from './hooks';
import Skeleton from './Skeleton';
import { filterProductsAdsByProducts } from './utils';

export interface RetailMediaProductsCarouselProps {
  title: string;
  slug: string;
}

function RetailMediaProductsCarousel(
  props: Readonly<RetailMediaProductsCarouselProps>
) {
  const { title, slug } = props;
  const { ref, inView } = useInView();

  const {
    data: productsAdsData,
    isError: isProductsAdsError,
    isFetching: isFetchingProductsAds,
  } = useProductsAds(slug);

  const {
    data: productsData,
    isError: isProductsError,
    isFetching: isFetchingProducts,
  } = useRecommendedProductsData({
    products:
      productsAdsData?.productsAds.map(({ product_sku }) => ({
        id: product_sku,
        clickTrackingURL: '',
      })) || [],
    title: '',
  });

  const filteredProductsAds = filterProductsAdsByProducts({
    productsAds: productsAdsData?.productsAds || [],
    products: productsData?.products || [],
  });

  useRunOnce(() => {
    filteredProductsAds.forEach(({ impression_url }) => {
      navigator.sendBeacon(impression_url);
    });
  }, Boolean(filteredProductsAds.length));

  useRunOnce(() => {
    filteredProductsAds.forEach(({ view_url }) => {
      navigator.sendBeacon(view_url);
    });
  }, inView && Boolean(filteredProductsAds.length));

  const hasError = isProductsAdsError || isProductsError;
  const isFetching =
    isFetchingProductsAds ||
    (isFetchingProducts && Boolean(productsAdsData?.productsAds.length));

  const hasNoProducts = !isFetching && !productsData?.products.length;

  if (hasError || hasNoProducts) {
    return <></>;
  }

  if (isFetching) {
    return <Skeleton />;
  }

  return (
    <div ref={ref}>
      <ProductsCarousel
        partnerName="retail media"
        type="products"
        title={title}
        items={productsData?.products || []}
        onProductClick={(_, { id: productId }) => {
          const productAds = filteredProductsAds.find(
            ({ product_sku }) => Number(product_sku) === productId
          );

          if (productAds) {
            navigator.sendBeacon(productAds.click_url);
          }
        }}
      />
    </div>
  );
}

export default RetailMediaProductsCarousel;
