import { toast } from '@backyard-ui/core';
import { useMutation } from '@tanstack/react-query';

import { Services } from '@/infra/services';
import {
  type Response,
  type Body,
} from '@/infra/services/vouchers/post-voucher-share';
import { type VoucherType } from '@/presenters/components/modular-content/components/VouchersCarousel/fixture';

import { useVoucherModalStore } from '../VoucherModal/store';

import { useVoucherQueryActions } from './useVoucherQueryActions';

interface UseAddServicesToCart {
  id: VoucherType['id'];
}

export const useSubmitVoucherShare = (
  props: Readonly<UseAddServicesToCart>
) => {
  const { id } = props;

  const { selectedCustomer, closeModal } = useVoucherModalStore((state) => ({
    selectedCustomer: state.selectedCustomer,
    closeModal: state.closeModal,
  }));

  const { updateVoucherQueryOnActivation } = useVoucherQueryActions();

  const mutation = useMutation<Response, unknown, Body>({
    mutationFn: Services.Vouchers.postVoucherShare,
    onSuccess: () => {
      updateVoucherQueryOnActivation(id);

      closeModal();

      toast({
        appearance: 'success',
        variant: 'solid',
        title: 'Sucesso!',
        description: `Seu cupom foi compartilhado com ${selectedCustomer?.customerName}`,
        isClosable: true,
      });
    },
    onError: () => {
      toast({
        appearance: 'critical',
        variant: 'solid',
        title: 'Não foi possível compartilhar esse cupom.',
        description: 'Tente novamente mais tarde.',
        isClosable: true,
      });
    },
  });

  return {
    ...mutation,
    shareVoucher: () => {
      if (!selectedCustomer?.customer.id) return;

      mutation.mutate({
        voucherId: id,
        inhabitantId: selectedCustomer.customer.id,
      });
    },
  };
};
