import { Heading, Stack, Text } from '@backyard-ui/core';

import { ActionButton, Footer, Grid, Header, Info } from '../';
import { useGetVoucherData } from '../../../hooks/useGetVoucherData';
import { type VoucherFullData } from '../../../model';
import { formatter } from '../../../utils/formatter';
import { useVoucherModalStore } from '../../store';

interface FirstStepProps {
  voucherId: VoucherFullData['id'];
}

function FirstStep(props: Readonly<FirstStepProps>) {
  const { voucherId } = props;

  const { data } = useGetVoucherData(voucherId);

  const goToNextStep = useVoucherModalStore((state) => state.goToNextStep);

  if (!data) return null;

  const { description, link, rules, price, isSharingAvailable } = data;

  return (
    <Grid voucherId={voucherId}>
      <Info>
        <Stack direction="column" spacing={6}>
          <Text size="3xl" weight="bold" color="gray-800" asChild>
            <span>Cupom compartilhável</span>
          </Text>

          {description && (
            <Stack direction="column" spacing={2}>
              <Heading size="sm" color="gray-700" asChild>
                <h3>Descrição</h3>
              </Heading>
              <Text size="sm" color="gray-500">
                {description}
              </Text>

              {link && (
                <div className="self-start">
                  <Text size="sm" color="primary-600" asChild>
                    <a href={link} target="_blank">
                      Veja a página do produto
                    </a>
                  </Text>
                </div>
              )}
            </Stack>
          )}

          {rules && (
            <Stack direction="column" spacing={2}>
              <Heading size="sm" color="gray-700" asChild>
                <h3>Regras de Utilização</h3>
              </Heading>
              <Text size="sm" color="gray-500">
                {rules}
              </Text>
            </Stack>
          )}
        </Stack>
      </Info>

      <Header voucherId={voucherId} subtitle="Cupom compartilhável" />

      <Footer voucherId={voucherId}>
        <div className="w-full space-y-2 text-center">
          <div>
            <Text color="gray-400">Valor do cupom</Text>
            <Text color="gray-400" weight="bold">
              {formatter.points(price)} pts
            </Text>
          </div>
          <ActionButton onClick={goToNextStep} isDisabled={!isSharingAvailable}>
            Compartilhar
          </ActionButton>
        </div>
      </Footer>
    </Grid>
  );
}

export default FirstStep;
