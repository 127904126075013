import { tv } from 'tailwind-variants';

export const carouselStyles = tv({
  base: `
   w-full
  `,
  slots: {
    container: `
      relative
      mx-auto
      max-w-6xl
    `,
    viewport: `
      overflow-hidden
    `,
    slides: `
      flex
      touch-pan-y
      select-none
      [&-img]:bg-gray-100
      [&_img]:block
      [&_img]:w-full
      [&_img]:min-w-full
      [&_img]:object-cover
    `,
    dragging: `
      cursor-grabbing
    `,
    draggable: `
      cursor-grab
    `,
  },
});

const controlsStyles = tv({
  base: `
    z-10
    cursor-pointer
    touch-manipulation
    overflow-hidden
    bg-transparent
    transition-colors
    duration-150
    ease-in
    disabled:cursor-not-allowed
    disabled:opacity-50
  `,
});

export const arrowStyles = tv({
  extend: controlsStyles,
  base: `
    absolute
    top-2/4
    hidden
    h-10
    w-10
    -translate-y-2/4
    rounded-full
    bg-white/75
    p-2.5
    text-gray-800
    shadow-xs
    hover:bg-primary-600
    hover:text-white
    lg:block
    [&_svg]:relative
    [&_svg]:h-full
    [&_svg]:w-full
  `,
  variants: {
    position: {
      left: `
        left-4
        [&_svg]:-left-0.5
      `,
      right: `
        right-4
        [&_svg]:-right-0.5
      `,
    },
  },
});

export const dotsStyles = tv({
  extend: controlsStyles,
  base: `
    absolute
    inset-x-auto
    bottom-2
    z-10
    flex
    w-full
    items-center
    justify-center
  `,
  slots: {
    item: `
      relative
      mx-2
      flex
      h-8
      w-8
      items-center
      after:flex
      after:h-1
      after:w-full
      after:rounded-sm
      after:bg-black-alpha-500
      after:content-['']
      data-[selected=true]:after:bg-green-600
      data-[selected=true]:after:opacity-100
    `,
  },
});
