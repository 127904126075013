import { type ReactNode } from 'react';

import { useGetVoucherData } from '../../../hooks/useGetVoucherData';
import { type VoucherFullData } from '../../../model';

import { styles } from './Footer.styles';

export interface FooterProps {
  voucherId: VoucherFullData['id'];
  children: ReactNode;
}

function Footer(props: Readonly<FooterProps>) {
  const { voucherId, children } = props;

  const { data } = useGetVoucherData(voucherId);

  const className = styles({
    isActive: data?.isActive,
    isShareable: data?.isShareable,
  });

  return <div className={className}>{children}</div>;
}

export default Footer;
