import { memo, forwardRef } from 'react';

import DOMPurify from 'isomorphic-dompurify';

import { styles } from './Markdown.styles';

interface MarkdownProps {
  content: string;
}

const Markdown = forwardRef<HTMLDivElement, MarkdownProps>((props, ref) => {
  const { content } = props;

  return (
    <div
      ref={ref}
      className={styles()}
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(content, { ADD_TAGS: ['iframe'] }),
      }}
    />
  );
});

Markdown.displayName = 'Markdown';

export default memo(Markdown);
