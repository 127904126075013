import { type ReactNode } from 'react';

import { ScrollArea } from '@backyard-ui/core';

export interface InfoProps {
  children: ReactNode;
}

function Info(props: Readonly<InfoProps>) {
  const { children } = props;

  return (
    <ScrollArea UNSAFE_className="[grid-area:info] md:bg-neutral-100">
      <div className="w-full overflow-hidden p-6">{children}</div>
    </ScrollArea>
  );
}

export default Info;
