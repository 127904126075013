import { Heading, Icon, Stack, Text } from '@backyard-ui/core';
import { GiftOutline } from '@backyard-ui/icons';

import {
  ActionButton,
  ActiveFooter,
  Grid,
  Header,
  Footer,
  Info,
  Steps,
} from '..';
import { FooterText } from '../../../components';
import { useActivateVoucher } from '../../../hooks/useActivateVoucher';
import { useGetVoucherData } from '../../../hooks/useGetVoucherData';
import { type VoucherFullData } from '../../../model';
import { formatter } from '../../../utils/formatter';

interface DefaultProps {
  voucherId: VoucherFullData['id'];
}

function Default(props: Readonly<DefaultProps>) {
  const { voucherId } = props;
  const { data } = useGetVoucherData(voucherId);

  const { activateVoucher, isLoading } = useActivateVoucher({
    voucherId,
    type: data?.type || 'percentage',
  });

  if (!data) return null;

  const { giftedBy, description, link, rules, price, isActivatingAvailable } =
    data;

  return (
    <Grid voucherId={voucherId}>
      <Info>
        <Stack direction="column" spacing="6">
          {giftedBy && (
            <Stack align="center" spacing="1">
              <Icon
                size="xs"
                appearance="tertiary"
                data-testid="voucher-modal-gift-flag"
              >
                <GiftOutline />
              </Icon>
              <Text size="sm" color="gray-800">
                Presente de {giftedBy}
              </Text>
            </Stack>
          )}

          <Stack direction="column" spacing="2">
            <Heading size="sm" color="gray-800" asChild>
              <h3>Descrição</h3>
            </Heading>
            <Text size="md" color="gray-500">
              {description}
            </Text>
            {link && (
              <Text size="sm" color="green-600" asChild>
                <a href={link} target="_blank">
                  Veja a página do produto
                </a>
              </Text>
            )}
          </Stack>

          <Stack direction="column" spacing="2">
            <Heading size="sm" color="gray-800" asChild>
              <h3>Regras de Utilização</h3>
            </Heading>
            <Text size="md" color="gray-500">
              {rules}
            </Text>
          </Stack>
        </Stack>
      </Info>

      <Header
        voucherId={voucherId}
        subtitle={
          data.type === 'money'
            ? `Troque ${formatter.points(price)} pontos por`
            : undefined
        }
      >
        <Steps voucherId={voucherId} />
      </Header>

      <Footer voucherId={voucherId}>
        {!data.isActive ? (
          <div className="w-full space-y-2 text-center">
            <ActionButton
              isLoading={isLoading}
              isDisabled={isLoading || !isActivatingAvailable}
              onClick={activateVoucher}
            >
              Ativar
            </ActionButton>
            <FooterText voucherId={voucherId} alwaysShowExpiration />
          </div>
        ) : (
          <ActiveFooter voucherId={voucherId} />
        )}
      </Footer>
    </Grid>
  );
}

export default Default;
