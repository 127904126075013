import { Markdown as MarkdownComponent } from '@/presenters/components/common/Markdown';

interface MarkdownProps {
  content: string;
}

function Markdown(props: Readonly<MarkdownProps>) {
  const { content } = props;

  return (
    <div className="mb-6 mt-8">
      <MarkdownComponent content={content} />
    </div>
  );
}

export default Markdown;
