import { useQuery } from '@tanstack/react-query';

import { Services } from '@/infra/services';
import { type Recommendation } from '@/infra/services/rich-relevance/model';

export function useRecommendedProductsData(
  recommendation?: Recommendation,
  userId?: string
) {
  const query = useQuery(
    ['rich-relevance-products', recommendation, userId],
    async () => {
      const title = recommendation!.title;

      if (!recommendation!.products.length) return { products: [], title };

      const productsData = recommendation!.products.map((item) => ({
        id: Number(item.id),
        trackingUrl: item.clickTrackingURL,
      }));

      const { data } = await Services.ModularContent.getRecommendedProductsData(
        {
          products: productsData,
          userId,
        }
      );

      return {
        products: data,
        title,
      };
    },
    { enabled: Boolean(recommendation) }
  );

  return query;
}
