import { tv } from 'tailwind-variants';

export const styles = tv({
  base: `
    group
    flex
    flex-col
    overflow-hidden
    rounded-md
    border
    border-gray-100
    bg-white
  `,
  slots: {
    imageWrapper: `
      overflow-hidden
    `,
    image: `
      h-full
      w-full
      transition-transform
      duration-100
      ease-in
      group-hover:scale-105
    `,
    content: `
      flex
      flex-col
      justify-between
      p-4
    `,
  },

  variants: {
    isInCarousel: {
      true: {
        base: `
          w-[theme('width.72')]
          min-w-[theme('width.72')]
          md:min-w-[theme('width.80')]
          [&:not(:last-child)]:mr-4
        `,
        imageWrapper: `
          h-44
        `,
        image: `
          md:h-[200px]
        `,
        content: `
          min-h-[theme('height.32')]
        `,
      },
    },
  },
});
