import { useCallback } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import { type ModularContent } from '@/infra/services/modular-content/model';

import { VOUCHER_BASE_QUERY_KEY } from '../constants';
import { type VoucherFullData } from '../model';

export const useVoucherQueryActions = () => {
  const queryClient = useQueryClient();

  const createVoucherQuery = useCallback(
    (voucher: ModularContent.VouchersData) => {
      queryClient.setQueryData([VOUCHER_BASE_QUERY_KEY, voucher.id], voucher);
    },
    [queryClient]
  );

  const updateVoucherQueryOnActivation = useCallback(
    (id: VoucherFullData['id']) => {
      const queryKey = [VOUCHER_BASE_QUERY_KEY, id];

      const previousData = queryClient.getQueryData<VoucherFullData>(queryKey);

      queryClient.setQueryData(queryKey, {
        ...previousData,
        isActive: true,
      });
    },
    [queryClient]
  );

  return {
    createVoucherQuery,
    updateVoucherQueryOnActivation,
  };
};
