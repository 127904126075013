'use client';

import { Flex } from '@backyard-ui/core';
import { useInView } from 'react-intersection-observer';

import { Banners } from '@/presenters/components/modular-content/components';
import { useRunOnce } from '@/presenters/hooks/use-run-once';

import { useBannersAds } from './hooks';
import Skeleton from './Skeleton';
import { buildModularBannersFromAd } from './utils';

export interface RetailMediaBannerProps {
  slug: string;
}

function RetailMediaBanner(props: Readonly<RetailMediaBannerProps>) {
  const { slug } = props;
  const { ref, inView } = useInView();

  const {
    data: bannersAdsData,
    isError: isBannersAdsError,
    isFetching: isFetchingBannersAds,
  } = useBannersAds(slug);

  const modularBanners = buildModularBannersFromAd(bannersAdsData);

  useRunOnce(() => {
    modularBanners.forEach(({ retailMedia: { impressionUrl } }) => {
      navigator.sendBeacon(impressionUrl);
    });
  }, Boolean(modularBanners.length));

  useRunOnce(() => {
    modularBanners.forEach(({ retailMedia: { viewUrl } }) => {
      navigator.sendBeacon(viewUrl);
    });
  }, Boolean(modularBanners.length) && inView);

  if (isFetchingBannersAds) {
    return <Skeleton />;
  }

  if (isBannersAdsError || !modularBanners.length) {
    return <></>;
  }

  return (
    <Flex ref={ref} justify="center">
      <div className="max-h-[120px] w-[980px]">
        <Banners
          {...modularBanners[0]}
          onClick={() => {
            navigator.sendBeacon(modularBanners[0].retailMedia.clickUrl);
          }}
        />
      </div>
    </Flex>
  );
}

export default RetailMediaBanner;
