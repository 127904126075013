'use client';

import { useEffect, type ComponentType } from 'react';

import { Flex, Spinner, Container } from '@backyard-ui/core';
import { ErrorBoundary } from 'react-error-boundary';
import { useInView } from 'react-intersection-observer';

import { ErrorReporter } from '@/infra/monitoring/error-reporter';
import {
  type PageType,
  type Response as ModulesType,
} from '@/infra/services/modular-content/get-modules';
import { type ModularContent as ModularContentType } from '@/infra/services/modular-content/model';
import { useGetModulesInfinite } from '@/presenters/hooks/modular-content/useGetModulesInfinite';
import { useGlobalStore } from '@/presenters/store/global';
import { USER_TRACKING_ID } from '@/utils/constants/cookies';
import { cookies } from '@/utils/cookies';

import {
  Banners,
  ContentGallery,
  Informative,
  LastContents,
  LeadCapture,
  Markdown,
  ProductsCarousel,
  RecommendedProductsCarousel,
  RetailMediaBanner,
  RetailMediaProductsCarousel,
  Slide,
  Thumbnails,
  VouchersCarousel,
} from './components';
import { styles } from './ModularContent.styles';

interface ModularContentProps {
  initialModules: ModulesType;
  slug: string;
  pageType: PageType;
}

const modulesWithoutContainer: ModularContentType.Module['type'][] = ['slide'];

interface ModulesComponentsType {
  // It's ok to use 'any' here because in this case it's checked at component level
  [key: string]: ComponentType<any>;
}

const isVoucherModuleEnabled =
  process.env.NEXT_PUBLIC_FEATURE_FLIP_MODULAR_VOUCHERS_ENABLED === 'true';

const modulesComponents: ModulesComponentsType = {
  markdown: Markdown,
  slide: Slide,
  banner: Banners,
  informative: Informative,
  leadCapture: LeadCapture,
  thumbnail: Thumbnails,
  lastContents: LastContents,
  beInspiredContents: ContentGallery,
  products: ProductsCarousel,
  recommendedProducts: RecommendedProductsCarousel,
  vouchers: isVoucherModuleEnabled ? VouchersCarousel : () => null,
  retailMediaSponsoredBanner: RetailMediaBanner,
  retailMediaSponsoredProducts: RetailMediaProductsCarousel,
};

export default function ModularContent(props: ModularContentProps) {
  const { initialModules, slug, pageType } = props;

  const userStore = useGlobalStore((state) => state.user);

  const { data, fetchNextPage, isFetchingNextPage } = useGetModulesInfinite(
    { slug, pageType },
    {
      initialData: {
        pages: [initialModules],
        pageParams: [1],
      },
    }
  );

  const { ref, inView } = useInView();

  const classNames = styles();

  useEffect(() => {
    if (!inView || isFetchingNextPage) return;

    fetchNextPage();
  }, [inView, fetchNextPage, isFetchingNextPage]);

  useEffect(() => {
    if (!userStore.resources) return;

    const cookieOptions = cookies.createOptions({
      name: USER_TRACKING_ID,
      value: userStore.resources?.userTrackingId!,
    });

    cookies.client.set(cookieOptions);
  }, [userStore]);

  if (!Boolean(data?.pages.length)) {
    return null;
  }

  return (
    <>
      <div>
        {data?.pages.map((page) => {
          if (!Boolean(page.data)) {
            return null;
          }

          return page.data?.results.map((item, index) => {
            if (!(item.type in modulesComponents)) return null;

            const Module = modulesComponents[item.type];

            const safeModule = (
              <ErrorBoundary
                key={index}
                fallback={<></>}
                onError={(error) =>
                  ErrorReporter.report(error, {
                    slug,
                    item,
                  })
                }
              >
                <Module {...item} slug={slug} pageType={pageType} />
              </ErrorBoundary>
            );

            const moduleComponent = modulesWithoutContainer.includes(
              item.type
            ) ? (
              safeModule
            ) : (
              <Container size="6xl">{safeModule}</Container>
            );

            const spacings =
              'spacings' in item ? item.spacings : { top: true, bottom: true };

            return (
              <section
                key={index}
                data-testid="module-wrapper"
                className={classNames.section({
                  hasBottomSpacing: spacings.bottom,
                  hasTopSpacing: spacings.top,
                })}
              >
                {moduleComponent}
              </section>
            );
          });
        })}
      </div>

      {isFetchingNextPage ? (
        <Flex align="center" justify="center" data-testid="spinner">
          <Spinner
            size="xl"
            appearance="primary"
            UNSAFE_className={classNames.spinner()}
          />
        </Flex>
      ) : (
        <div
          ref={ref}
          className={classNames.observerRef()}
          data-testid="intersector-ref"
        />
      )}
    </>
  );
}
