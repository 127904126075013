import { type RemoteVoucherBasicData } from '@/infra/services/modular-content/get-vouchers-data/get-vouchers-data';
import { type RemoteVoucherDetails } from '@/infra/services/vouchers/model';
import { isUrl } from '@/utils/validators/validators';

import { type VoucherFullData } from '../model';

type VoucherRemoteData = RemoteVoucherBasicData & Partial<RemoteVoucherDetails>;

export const voucherAdapter = (
  data: VoucherRemoteData,
  loyaltyPoints: number
): VoucherFullData => {
  const { thumbnail = undefined, isShared, price = 0, ...rest } = data;

  const thumbnailKey = isUrl(data?.thumbnail || '')
    ? 'thumbnailImage'
    : 'thumbnailCategory';

  const isActivatingAvailable = price <= loyaltyPoints;
  const isSharingAvailable = Boolean(
    isShared && isActivatingAvailable && !data.isActive
  );

  return {
    ...rest,
    ...(thumbnail && { [thumbnailKey]: thumbnail }),
    price,
    isShareable: isShared,
    description: data.description ?? undefined,
    link: data.link ?? undefined,
    rules: data.rules ?? undefined,
    isActivatingAvailable,
    isSharingAvailable,
  };
};
