import { Heading, Stack, Text } from '@backyard-ui/core';

function ErrorMessage() {
  return (
    <div className="p-6">
      <Stack direction="column">
        <Heading asChild>
          <h4>Atenção!</h4>
        </Heading>
        <Text>
          Ocorreu um problema ao trazer as informações do cupom. Por favor,
          tente novamente mais tarde.
        </Text>
      </Stack>
    </div>
  );
}

export default ErrorMessage;
