import { tv } from 'tailwind-variants';

export const styles = tv({
  base: `
    relative 
    flex-[0_0_100%]
  `,
  slots: {
    carousel: `
      relative

      before:md:absolute
      before:md:left-0
      before:md:right-0
      before:md:top-0

      before:md:-z-10

      before:md:h-full
      before:md:w-full

      before:md:bg-gradient-to-r
      before:md:from-[var(--slide-color-start)]
      before:md:from-50%
      before:md:to-[var(--slide-color-end)]
      before:md:to-50%

      before:md:content-['']
    `,
    countdown: `
      absolute 
      left-0 
      top-0 
      w-full 
      lg:bottom-0 
      lg:left-auto 
      lg:right-0 
      lg:top-auto 
      lg:w-auto
    `,
  },
});
