import { Icon, Stack, Text } from '@backyard-ui/core';
import { CheckmarkCircleOutline } from '@backyard-ui/icons';

import { useGetVoucherData } from '../../../hooks/useGetVoucherData';
import { type VoucherFullData } from '../../../model';

import { styles } from './Steps.styles';

const STEPS = [
  'Ative seu desconto',
  'Adicione produtos ao carrinho',
  'Confira o desconto no carrinho',
];

interface StepsProps {
  voucherId: VoucherFullData['id'];
}

function Steps(props: Readonly<StepsProps>) {
  const { voucherId } = props;

  const { data } = useGetVoucherData(voucherId);

  if (!data) return null;

  const { isActive } = data;

  const classNames = styles({ isActive });

  return (
    <div className={classNames.base()}>
      <Stack direction="column" spacing={{ initial: 2, md: 4 }}>
        {STEPS.map((step, index) => (
          <Stack spacing="2" align="center" key={step}>
            {index === 0 && isActive ? (
              <Icon
                color="white"
                size={{ initial: 'sm', md: 'lg' }}
                data-testid="active-step-icon"
              >
                <CheckmarkCircleOutline />
              </Icon>
            ) : (
              <div className={classNames.stepBullet()}>
                <span>{index + 1}</span>
              </div>
            )}

            <div className={classNames.stepText()}>
              <Text size="md" color="current">
                {step}
              </Text>
            </div>
          </Stack>
        ))}
      </Stack>
    </div>
  );
}

export default Steps;
