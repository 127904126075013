'use client';

import { type ReactNode, useEffect, useRef } from 'react';

import { useRichRelevancePlacements } from '../../hooks/use-rich-relevance-placements';
import { useGlobalStore } from '../global';

import {
  Provider,
  createModularContentStore,
  type ModularContentStoreApi,
} from './context';

export interface ModularContentContextProviderProps {
  id?: string;
  name: string;
  children: ReactNode;
  placements: string[];
}

const ModularContentContextProvider = ({
  id,
  name,
  children,
  placements,
}: ModularContentContextProviderProps) => {
  const storeRef = useRef<ModularContentStoreApi>();

  if (!storeRef.current) {
    storeRef.current = createModularContentStore({
      id,
      name,
      recommendations: {
        isLoading: false,
        isError: false,
      },
    });
  }

  const userStore = useGlobalStore((state) => state.user);

  const query = useRichRelevancePlacements(placements, {
    region: userStore.session.region,
    userId: userStore.resources?.id,
    sessionId: userStore.resources?.userTrackingId,
  });

  useEffect(() => {
    if (!storeRef.current) return;

    const { setRecommendations } = storeRef.current.getState();

    setRecommendations({
      isLoading: query.isLoading,
      isError: query.isError,
      data: query.data,
    });
  }, [query]);

  return <Provider value={storeRef.current}>{children}</Provider>;
};

export default ModularContentContextProvider;
