import { Text } from '@backyard-ui/core';

import {
  ActionButton,
  Footer,
  Grid,
  Header,
  Info,
  BackButton,
  ButtonGroup,
  CustomersList,
} from '../';
import { useGetVoucherData } from '../../../hooks/useGetVoucherData';
import { type VoucherFullData } from '../../../model';
import { useVoucherModalStore } from '../../store';

interface SecondsStepProps {
  voucherId: VoucherFullData['id'];
}

function SecondStep(props: Readonly<SecondsStepProps>) {
  const { voucherId } = props;

  const { data: voucherData } = useGetVoucherData(voucherId);

  const { goToNextStep, selectedCustomer } = useVoucherModalStore((state) => ({
    goToNextStep: state.goToNextStep,
    selectedCustomer: state.selectedCustomer,
  }));

  if (!voucherData) return null;

  return (
    <Grid voucherId={voucherId}>
      <Info>
        <div className="space-y-6">
          <div className="space-y-1">
            <Text size="3xl" weight="bold" color="gray-800" asChild>
              <span>Escolha um cliente para compartilhar</span>
            </Text>
            <Text size="md" color="gray-500">
              Escolha apenas um de seus clientes ativos para enviar o cupom
            </Text>
          </div>

          <CustomersList />
        </div>
      </Info>

      <Header voucherId={voucherId} subtitle="Cupom compartilhável" />
      <Footer voucherId={voucherId}>
        <ButtonGroup>
          <BackButton />
          <ActionButton onClick={goToNextStep} isDisabled={!selectedCustomer}>
            Continuar
          </ActionButton>
        </ButtonGroup>
      </Footer>
    </Grid>
  );
}

export default SecondStep;
