import { type MouseEvent } from 'react';

import { Button, Icon, Stack, Text } from '@backyard-ui/core';
import { Checkmark } from '@backyard-ui/icons';

import { useGetVoucherData } from '../../../hooks/useGetVoucherData';
import { type VoucherFullData } from '../../../model';
import { formatter } from '../../../utils/formatter';

export interface ActivationButtonProps {
  voucherId: VoucherFullData['id'];
  isDisabled: boolean;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  isLoading?: boolean;
  className?: string;
}

function ActivationButton(props: Readonly<ActivationButtonProps>) {
  const { voucherId, className, isDisabled, onClick, isLoading } = props;

  const { data } = useGetVoucherData(voucherId);

  if (!data) return null;

  if (data.isActive)
    return (
      <div className={className}>
        <Stack justify="center" align="center" spacing="3">
          <Icon size="xs">
            <Checkmark />
          </Icon>
          <Text weight="bold" color="current">
            Ativado
          </Text>
        </Stack>
      </div>
    );

  return (
    <div className={className}>
      <Button
        size="md"
        appearance="tertiary"
        disabled={isDisabled}
        onClick={onClick}
        isLoading={isLoading}
        isFullWidth
      >
        {data.type === 'money'
          ? `${formatter.points(data.price)} pts`
          : 'Ativar'}
      </Button>
    </div>
  );
}

export default ActivationButton;
