import { useEffect, useRef } from 'react';

export const useRunOnce = (callback: () => any, condition: boolean) => {
  const hasAlreadyRun = useRef(false);

  useEffect(() => {
    if (condition && !hasAlreadyRun.current) {
      callback();
      hasAlreadyRun.current = true;
    }
  }, [callback, condition]);
};
