import { useQuery } from '@tanstack/react-query';

import { ErrorReporter } from '@/infra/monitoring/error-reporter';
import { Services } from '@/infra/services';
import {
  type UserData,
  type RichRelevanceOptions,
} from '@/infra/services/rich-relevance/model';
import { RICH_RELEVANCE_RCS } from '@/utils/constants/cookies';
import { cookies } from '@/utils/cookies';
import { parsePlacementsData } from '@/utils/parse-placements-data';

export function useRichRelevancePlacements(
  placements: string[],
  userData: UserData,
  richRelevanceOptions?: RichRelevanceOptions
) {
  const hasLoadedUserData = Boolean(userData.sessionId);
  const query = useQuery(
    ['rich-relevance-placements', placements, userData],

    async () => {
      const rcsCookie = cookies.client.get(RICH_RELEVANCE_RCS);

      try {
        const data = await Services.RichRelevance.getProductsByPlacements({
          userData,
          rcs: rcsCookie.value,
          placements: placements.join('|'),
          options: richRelevanceOptions,
        });

        if (data?.rcs) {
          const rcsCookieOptions = cookies.createOptions({
            name: RICH_RELEVANCE_RCS,
            value: data.rcs,
          });

          cookies.client.set(rcsCookieOptions);
        }

        return parsePlacementsData(data?.placements || []);
      } catch (error) {
        ErrorReporter.report(error);
      }
    },
    { enabled: hasLoadedUserData }
  );

  return query;
}
