import { Dialog } from '@backyard-ui/core';

import { type VoucherFullData } from '../model';

import { Content } from './components';
import { useVoucherModalStore } from './store';

export interface VoucherModalProps {
  voucherId: VoucherFullData['id'];
}

function VoucherModal(props: Readonly<VoucherModalProps>) {
  const { voucherId } = props;

  const { checkIsOpen, openModal, closeModal } = useVoucherModalStore(
    (state) => ({
      checkIsOpen: state.checkIsOpen,
      openModal: state.openModal,
      closeModal: state.closeModal,
    })
  );

  const handleOpenChange = (open: boolean) => {
    !open ? closeModal() : openModal(voucherId);
  };

  const isOpen = checkIsOpen(voucherId);

  return (
    <div data-testid={isOpen ? 'voucher-modal-open' : 'voucher-modal-closed'}>
      <Dialog.Root isOpen={isOpen} onOpenChange={handleOpenChange} size="2xl">
        <Dialog.Content UNSAFE_className="max-md:items-end [&>div]:max-h-[calc(100%-4rem)] [&>div]:p-0 max-md:[&>div]:mb-0 max-md:[&>div]:rounded-b-none">
          <div className="grid min-h-[450px] w-full overflow-hidden rounded-[inherit] md:h-[450px]">
            <Content voucherId={voucherId} />
          </div>
          <Dialog.CloseButton />
        </Dialog.Content>
      </Dialog.Root>
    </div>
  );
}

export default VoucherModal;
