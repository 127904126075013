import { useCallback } from 'react';

import { useQuery, useQueryClient } from '@tanstack/react-query';

import { Services } from '@/infra/services';
import { type RemoteVoucherBasicData } from '@/infra/services/modular-content/get-vouchers-data/get-vouchers-data';
import { type RemoteVoucherDetails } from '@/infra/services/vouchers/model';
import { useGlobalStore } from '@/presenters/store/global';

import { VOUCHER_BASE_QUERY_KEY } from '../constants';
import { type VoucherFullData } from '../model';
import { voucherAdapter } from '../utils/voucherAdapter';

type VoucherRemoteData =
  | RemoteVoucherBasicData
  | (RemoteVoucherBasicData & RemoteVoucherDetails);

export function useGetVoucherData(
  id: VoucherFullData['id'],
  isEnabled: boolean = false
) {
  const queryClient = useQueryClient();

  const previousData = queryClient.getQueryData<VoucherRemoteData>([
    VOUCHER_BASE_QUERY_KEY,
    id,
  ]);

  const hasFullData =
    previousData &&
    'description' in previousData &&
    Boolean(previousData.description);

  const loyaltyPoints = useGlobalStore(
    (state) => state.user?.loyaltyProfile?.points.actual
  );

  const query = useQuery({
    queryKey: [VOUCHER_BASE_QUERY_KEY, id],
    queryFn: async () => {
      if (!previousData) return undefined;

      const data = await Services.Vouchers.getVoucherModalData({
        voucherId: id,
      });

      const result: VoucherRemoteData = { ...previousData, ...data };

      return result;
    },
    select: useCallback(
      (data: VoucherRemoteData | undefined) => {
        if (!data) return undefined;
        return voucherAdapter(data, loyaltyPoints ?? 0);
      },
      [loyaltyPoints]
    ),
    enabled: isEnabled && !hasFullData,
  });

  return query;
}
