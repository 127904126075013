import { usePathname, useSearchParams } from 'next/navigation';

export const useUrl = () => {
  const basePath = process.env.NEXT_PUBLIC_ENDPOINT_LEROY;
  const pathname = usePathname();
  const searchParams = useSearchParams();

  if (searchParams.toString()) {
    return `${basePath}${pathname}?${searchParams}`;
  } else {
    return `${basePath}${pathname}`;
  }
};
