import { memo, type MouseEvent } from 'react';

import { Stack, Heading, Text } from '@backyard-ui/core';
import { getDepartmentIcon } from '@leroy-merlin-br/backpack-header';

import { type ModularContent } from '@/infra/services/modular-content/model';
import { GtmDataAttributesGenerator } from '@/infra/tracking/gtm-data-attributes';
import * as tracker from '@/infra/tracking/tracker';
import { Carousel } from '@/presenters/components/common/Carousel';
import { Image } from '@/presenters/components/common/Image';
import { useModularContentStore } from '@/presenters/store/modular-content';

import { thumbnailStyles, carouselStyles } from './Thumbnails.styles';

export interface ThumbnailsProps extends ModularContent.Thumbnails {}

function Thumbnails(props: ThumbnailsProps) {
  const { items, title: moduleTitle, elementsType } = props;

  const contentName = useModularContentStore((state) => state.name);

  const thumbnaiClassNames = thumbnailStyles({
    isBlackFriday:
      process.env.NEXT_PUBLIC_FEATURE_FLIP_BLACK_FRIDAY_ENABLED === 'true',
  });

  const carouselClassnames = carouselStyles();

  const onThumbnailClick = (
    event: MouseEvent<HTMLAnchorElement>,
    thumbnail: ThumbnailsProps['items'][number]
  ) => {
    event.preventDefault();

    const { image, url, title } = thumbnail;

    tracker.onThumbnailClick({
      id: image.id,
      itemTitle: title,
      moduleTitle: moduleTitle,
      contentName: contentName,
    });

    window.location.href = url;
  };

  return (
    <Stack data-testid="thumbnail" direction="column" spacing={4}>
      <Heading size="2xl" asChild>
        <h2>{moduleTitle}</h2>
      </Heading>

      <Carousel
        options={{
          containScroll: 'trimSnaps',
          dragFree: true,
        }}
        className={carouselClassnames}
      >
        {items?.map((thumb, index) => {
          const { image } = thumb;
          const IconComponent = getDepartmentIcon(image.id);

          return (
            <a
              key={image.id}
              href={thumb.url}
              onClick={(event) => onThumbnailClick(event, thumb)}
              className={thumbnaiClassNames.base()}
              data-testid="thumb-link"
              {...GtmDataAttributesGenerator.banner({
                id: image.id,
                elementType: elementsType,
                type: 'carousel',
                name: thumb.title,
                index: index,
                listName: moduleTitle ?? '',
              })}
            >
              {IconComponent ? (
                <div className={thumbnaiClassNames.icon()}>
                  <IconComponent data-testid="thumb-icon" />
                </div>
              ) : (
                <Image
                  src={image.url}
                  alt={image.alt}
                  width={image.size.width}
                  height={image.size.height}
                  className={thumbnaiClassNames.image()}
                  data-testid="thumb-image"
                />
              )}
              <Text size="md" align="center" weight="bold">
                {thumb.title}
              </Text>
            </a>
          );
        })}
      </Carousel>
    </Stack>
  );
}

export default memo(Thumbnails);
