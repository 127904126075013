import { memo } from 'react';

import { Stack, Text } from '@backyard-ui/core';
import { ShareOutline } from '@backyard-ui/icons';

import { FooterText, Picture, GiftFlag } from '../components';
import { useActivateVoucher } from '../hooks/useActivateVoucher';
import { useGetVoucherData } from '../hooks/useGetVoucherData';
import { type VoucherFullData } from '../model';
import { VoucherModal } from '../VoucherModal';
import { useVoucherModalStore } from '../VoucherModal/store';

import { ActivationButton } from './components';
import { styles } from './Voucher.styles';

interface VoucherProps extends Pick<VoucherFullData, 'id'> {
  isDisabled?: boolean;
}

function Voucher(props: Readonly<VoucherProps>) {
  const { id, isDisabled } = props;

  const { data } = useGetVoucherData(id);

  const openModal = useVoucherModalStore((state) => state.openModal);

  const { activateVoucher, isLoading } = useActivateVoucher({
    voucherId: id,
    type: data?.type || 'percentage',
  });

  if (!data) {
    return null;
  }

  const {
    giftedBy,
    isActive,
    isShareable,
    shortDescription,
    title,
    type,
    isActivatingAvailable,
  } = data;

  const handleOpenModal = () => {
    !isDisabled && openModal(id);
  };

  const handleButtonClick = () => {
    type === 'money' ? handleOpenModal() : activateVoucher();
  };

  const classNames = styles({ isActive });

  return (
    <>
      <VoucherModal voucherId={id} />

      <div className={classNames.base()} data-testid="lmcv-voucher">
        <div className={classNames.content()}>
          <Stack direction="column" spacing="2">
            <button
              className={classNames.info()}
              onClick={handleOpenModal}
              data-testid="lmcv-voucher-top"
              disabled={isDisabled}
            >
              <Picture voucherId={id} placement="thumb" />
              <div className={classNames.title()}>
                <Text
                  size="lg"
                  weight="bold"
                  align="center"
                  noOfLines={2}
                  color="current"
                >
                  {title}
                </Text>
              </div>
              {shortDescription && (
                <div className={classNames.description()}>
                  <Text size="md" align="center" noOfLines={2} color="current">
                    {shortDescription}
                  </Text>
                </div>
              )}
            </button>

            <div className={classNames.divider()} />

            <ActivationButton
              voucherId={id}
              onClick={handleButtonClick}
              isDisabled={isDisabled || !isActivatingAvailable || isLoading}
              className={classNames.buttonWrapper()}
              isLoading={isLoading}
            />

            <FooterText voucherId={id} />
          </Stack>
        </div>

        {giftedBy && (
          <div className={classNames.giftFlag()} data-testid="gift-flag">
            <GiftFlag voucherId={id} />
          </div>
        )}

        {isShareable && (
          <div className={classNames.shareIcon()} data-testid="shared-flag">
            <ShareOutline />
          </div>
        )}
      </div>
    </>
  );
}

export default memo(Voucher);
