import { tv } from 'tailwind-variants';

export const thumbnailStyles = tv({
  base: `
    flex
    w-32
    shrink-0
    flex-col
    [&:not(:last-child)]:mr-4
  `,
  slots: {
    icon: `
      mb-2
      flex
      h-32
      w-full
      items-center
      justify-center
      rounded-md
      [&_svg]:h-14
      [&_svg]:w-14
      [&_svg]:text-white
    `,
    image: `
      mb-2
      w-full
      rounded-md
      bg-gray-100
    `,
  },
  variants: {
    isBlackFriday: {
      true: {
        icon: 'bg-black',
      },
      false: {
        icon: 'bg-blue-900',
      },
    },
  },
});

export const carouselStyles = tv({
  base: `
    [&>div]:flex
    [&>div]:justify-center`,
});
