import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.extend(isSameOrBefore);
dayjs.locale('pt-br');

export const formatter = {
  remainingCouponDays: (days: number) => {
    const daysLegend = days === 1 ? 'dia' : 'dias';
    return days === 0 ? 'Expira hoje' : `Expira em ${days} ${daysLegend}`;
  },
  points: (value: number) => {
    return value.toLocaleString('pt-BR');
  },
  formatDate: (date: string, template: string) => dayjs(date).format(template),
};
