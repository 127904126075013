import { useMemo } from 'react';

import Image from 'next/image';

import {
  PercentSignOutline,
  DollarSignOutline,
  MoneyOutline,
  TicketPercentOutline,
} from '@backyard-ui/icons';

import { getDepartmentIconBySlug } from '@/utils/icons/get-department-icon';

import { useGetVoucherData } from '../../hooks/useGetVoucherData';
import { type VoucherFullData } from '../../model';

import { styles } from './Picture.styles';

export interface PictureProps {
  voucherId: VoucherFullData['id'];
  placement: 'modal' | 'thumb';
}

function Picture(props: Readonly<PictureProps>) {
  const { voucherId, placement } = props;

  const { data } = useGetVoucherData(voucherId);

  const { isActive, thumbnailCategory, thumbnailImage, type, title } =
    data || {};

  const image = useMemo(() => {
    if (thumbnailImage) {
      return (
        <Image src={thumbnailImage} alt={title || ''} width={80} height={80} />
      );
    }

    // O ícone deve vir do getDepartmentIcon do backpack-header quando a api retornar o id em vez do slug
    const IconComponent =
      thumbnailCategory && getDepartmentIconBySlug(thumbnailCategory);

    if (IconComponent) {
      return <IconComponent data-testid="department-icon" />;
    }

    return type === 'money' ? (
      <MoneyOutline data-testid="money-icon" />
    ) : (
      <TicketPercentOutline data-testid="ticket-icon" />
    );
  }, [thumbnailCategory, thumbnailImage, title, type]);

  const classNames = styles({ isActive, placement });

  return (
    <div className={classNames.base()}>
      {image}
      <div className={classNames.pictureIcon()}>
        {type === 'percentage' ? (
          <PercentSignOutline data-testid="picture-icon-percent" />
        ) : (
          <DollarSignOutline data-testid="picture-icon-money" />
        )}
      </div>
    </div>
  );
}

export default Picture;
