import { Spinner as SpinnerBase } from '@backyard-ui/core';

function Spinner() {
  return (
    <div
      className="flex h-full w-full items-center justify-center"
      data-testid="voucher-modal-spinner"
    >
      <SpinnerBase />
    </div>
  );
}

export default Spinner;
