import { Default, ErrorMessage, Spinner, Share } from '../';
import { useGetVoucherData } from '../../../hooks/useGetVoucherData';
import { type VoucherFullData } from '../../../model';
import { useVoucherModalStore } from '../../store';

export interface ContentProps {
  voucherId: VoucherFullData['id'];
}

function Content(props: Readonly<ContentProps>) {
  const { voucherId } = props;

  const checkIsOpen = useVoucherModalStore((state) => state.checkIsOpen);

  const isOpen = checkIsOpen(voucherId);

  const { data, isFetching, isError } = useGetVoucherData(voucherId, isOpen);

  if (isFetching) {
    return (
      <div className="h-full">
        <Spinner />
      </div>
    );
  }

  if (isError || !data) {
    return <ErrorMessage />;
  }

  return data.isShareable ? (
    <Share voucherId={voucherId} />
  ) : (
    <Default voucherId={voucherId} />
  );
}

export default Content;
