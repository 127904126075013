import { create } from 'zustand';

import { type Customer } from '@/infra/services/vouchers/get-customers';

export interface VoucherModalState {
  openVoucherId?: string;
  step: number;
  selectedCustomer?: Customer;
}

export interface VoucherModalActions extends VoucherModalState {
  openModal: (id?: string) => void;
  closeModal: () => void;
  checkIsOpen: (id: string) => boolean;

  goToNextStep: () => void;
  goToPreviousStep: () => void;
  selectCustomer: (value: VoucherModalState['selectedCustomer']) => void;
}

export const useVoucherModalStore = create<VoucherModalActions>((set, get) => ({
  openModal: (id) => set({ openVoucherId: id }),
  closeModal: () =>
    set({ openVoucherId: undefined, selectedCustomer: undefined, step: 1 }),
  checkIsOpen: (id) => id === get().openVoucherId,

  step: 1,
  goToNextStep: () =>
    set((state) => {
      if (state.step === 3) {
        return { step: 3 };
      }

      return { step: state.step + 1 };
    }),
  goToPreviousStep: () =>
    set((state) => {
      if (state.step === 1) {
        return { step: 1, selectedCustomer: undefined };
      }

      return { step: state.step - 1, selectedCustomer: undefined };
    }),
  selectCustomer: (value) => set({ selectedCustomer: value }),
}));
