import { tv } from 'tailwind-variants';

export const styles = tv({
  slots: {
    base: `
      relative
      flex
      h-full
      min-w-full
      flex-col
      justify-start
      gap-2
      px-6
      py-3
      shadow-md
      [grid-area:header]
      md:items-center
      md:gap-4
      md:py-6
      md:shadow-none
    `,
    picture: `
      shrink-0
    `,
    title: `
      flex
      flex-col
      items-start
      md:items-center
      [&>p]:text-left
      md:[&>p]:text-center
    `,
    content: `
      border-t
      pt-2
      md:pt-4
    `,
  },
  variants: {
    isActive: {
      true: {
        base: `
          bg-loyalty-gradient
          md:bg-none
        `,
        title: `
          [&>p:first-child]:text-violet-400
          [&>p:last-child]:last:text-white
        `,
        content: `
          border-t-[rgba(255,255,255,0.1)]
        `,
      },
      false: {
        title: `
          [&>p:first-child]:text-gray-400
          [&>p:last-child]:last:text-gray-800
        `,
        content: `
          border-t-gray-100
        `,
      },
    },
  },
});
