import { tv } from 'tailwind-variants';

export const styles = tv({
  base: `
   relative
   h-[300px]
   w-[152px]
   drop-shadow-[0_1px_4px_rgba(29,28,27,0.15)]
   lg:w-[172px]
  `,
  slots: {
    content: `
      h-full
      w-full
      rounded-xl
      bg-white
      p-4
      pb-1.5
      mask-[radial-gradient(12px_at_12px_208px,#0000_calc(100%-1px),#000)_-12px]
      [&>div]:h-full
    `,
    info: `
      flex
      flex-1
      cursor-pointer
      flex-col
      items-center
      gap-2
      disabled:cursor-not-allowed

    `,
    title: `
      text-gray-900
      [&>p]:leading-5
    `,
    description: `
      text-gray-400
    `,
    divider: `
      w-full
      border-b-[6px]
      border-dotted
      border-neutral-100
    `,
    buttonWrapper: `
      w-full
      text-violet-400
      [&>div]:h-12
    `,
    shareIcon: `
      absolute
      right-4
      top-3
      z-[3]

      [&>svg]:h-4
      [&>svg]:w-4
      [&>svg]:text-violet-600
    `,
    giftFlag: `
      absolute
      right-4
      top-0
      z-[3]
    `,
  },

  variants: {
    isActive: {
      true: {
        content: `
          bg-loyalty-gradient
        `,
        picture: `
          border-violet-500
          [&>svg]:fill-white
          [&>svg]:text-white
        `,
        title: `
          text-white
        `,
        description: `
          text-violet-400
        `,
        pictureIcon: `
          border-violet-500
          bg-violet-500
          [&>svg]:text-white
        `,
        topIcon: `
          [&>svg]:text-white
        `,
      },
    },
  },
});
