import { toast } from '@backyard-ui/core';
import { type UseMutationOptions, useMutation } from '@tanstack/react-query';

import { Services } from '@/infra/services';
import { type VoucherActivationError } from '@/infra/services/vouchers/model';
import {
  type Response,
  type Body,
} from '@/infra/services/vouchers/post-voucher-activation';
import { type VoucherFullData } from '@/presenters/components/common/Vouchers/model';

import { useVoucherModalStore } from '../VoucherModal/store';

import { useVoucherQueryActions } from './useVoucherQueryActions';

type FullOptions = UseMutationOptions<Response, VoucherActivationError, Body>;
type Options = Pick<FullOptions, 'onSuccess' | 'onError'>;

type ActivationData = {
  voucherId: VoucherFullData['id'];
  type: VoucherFullData['type'];
};

export const useActivateVoucher = (data: ActivationData, options?: Options) => {
  const { voucherId, type } = data;
  const { updateVoucherQueryOnActivation } = useVoucherQueryActions();
  const closeModal = useVoucherModalStore((state) => state.closeModal);

  const mutation = useMutation<Response, VoucherActivationError, Body>({
    ...options,
    mutationFn: Services.Vouchers.postVoucherActivation,
    onSuccess: (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);

      updateVoucherQueryOnActivation(data.voucher.id);

      closeModal();

      toast({
        title: 'Sucesso!',
        description: 'Cupom ativado com sucesso',
        appearance: 'success',
        variant: 'solid',
        isClosable: true,
      });
    },
    onError: (error, variables, context) => {
      options?.onError?.(error, variables, context);

      toast({
        title: 'Erro!',
        description: 'Erro ao ativar cupom',
        appearance: 'critical',
        variant: 'solid',
        isClosable: true,
      });
    },
  });

  return {
    ...mutation,
    activateVoucher: () => mutation.mutate({ voucherId, type }),
  };
};
