import { type ReactNode } from 'react';

import { Button, type ButtonProps } from '@backyard-ui/core';

export interface ActionButtonProps
  extends Omit<ButtonProps, 'size' | 'appearance' | 'isFullWidth'> {
  children: ReactNode;
  onClick: () => void;
}

function ActionButton(props: Readonly<ActionButtonProps>) {
  const { children, onClick, ...rest } = props;

  return (
    <Button
      size="md"
      appearance="tertiary"
      onClick={onClick}
      isFullWidth
      {...rest}
    >
      {children}
    </Button>
  );
}

export default ActionButton;
