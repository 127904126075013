import { Text, type TextProps } from '@backyard-ui/core';

import { type ModularContent } from '@/infra/services/modular-content/model';

import { formatPrice } from '../../ProductThumb.utils';

type Price = ModularContent.Product['price'];
type priceDetailsColor = TextProps['color'];

export interface InstallmentsProps {
  /**
   *
   */
  textColor?: priceDetailsColor;

  /**
   *
   */
  price: Price;

  /**
   * value that determines if it should not show the product as a offer even it having discount value
   */
  featureInstallmentDiscount: boolean;
}

function Installments(props: Readonly<InstallmentsProps>) {
  const { price, featureInstallmentDiscount, textColor } = props;

  if (!price?.installments) {
    return null;
  }

  const { suggested, from, installments } = price;
  const { amount, value } = installments;

  const showFromPrice = (featureInstallmentDiscount || suggested) && !!from;

  return (
    <Text
      color={textColor}
      size="sm"
      weight="bold"
      data-testid="ProductThumb-installments"
      asChild
    >
      <span>
        {showFromPrice && `${formatPrice(from)} em até `}
        {amount}x de {formatPrice(value)} sem juros
      </span>
    </Text>
  );
}

export default Installments;
