import React, { type SVGProps } from 'react';

import { useGetVoucherData } from '../../hooks/useGetVoucherData';
import { type VoucherFullData } from '../../model';

interface GiftFlagProps extends SVGProps<SVGSVGElement> {
  voucherId: VoucherFullData['id'];
}

function GiftFlag(props: Readonly<GiftFlagProps>) {
  const { voucherId, ...rest } = props;

  const { data } = useGetVoucherData(voucherId);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={26}
      fill="none"
      {...rest}
    >
      <path
        fill={data?.isActive ? '#fff' : 'url(#linear-gift-flag)'}
        d="M18 16.787V3a3 3 0 0 0-3-3H3a3 3 0 0 0-3 3v13.787a3 3 0 0 0 1.028 2.26l6 5.233a3 3 0 0 0 3.944 0l6-5.232A3 3 0 0 0 18 16.787Z"
      />
      <path
        fill={data?.isActive ? 'url(#linear-gift-flag)' : '#fff'}
        fillRule="evenodd"
        d="m13.97 6.803-2.176.055c.195-.28.38-.64.335-1-.025-.197-.13-.476-.5-.68-.75-.41-1.57-.02-1.895.25-.355.283-.58.552-.72.785a3.627 3.627 0 0 0-.745-.786c-.33-.264-1.15-.66-1.894-.248-.37.203-.475.482-.5.68-.045.354.14.72.335.999H4.07c-.04 0-.07.03-.07.07V9.42c0 .04.03.07.07.07h.42v5.029c0 .26.18.481.523.481h7.993c.288 0 .458-.202.458-.481V9.491h.465c.04 0 .07-.03.07-.071V6.929c0-.04.01-.126-.03-.126Zm-4.055-.9c.28-.223.865-.466 1.345-.197.195.106.23.223.24.294.035.3-.156.635-.356.853h-1.86c.035-.193.051-.478.631-.95ZM6.5 6c.01-.076.045-.193.24-.3.48-.263 1.07-.025 1.345.199.565.456.624.746.684.959H6.855c-.2-.218-.39-.559-.355-.858Zm1.78 9V9.89h1.37V15H8.28Z"
        clipRule="evenodd"
      />
      <defs>
        <linearGradient
          id="linear-gift-flag"
          x1={-1.108}
          x2={24.594}
          y1={-1.2}
          y2={16.335}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9A21AA" />
          <stop offset={1} stopColor="#7808CF" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default GiftFlag;
