import { Stack, Text, Icon } from '@backyard-ui/core';
import { CheckmarkCircleOutline } from '@backyard-ui/icons';

import { useGetVoucherData } from '../../../hooks/useGetVoucherData';
import { type VoucherFullData } from '../../../model';
import { formatter } from '../../../utils/formatter';

import { styles } from './ActiveFooter.styles';

interface ActiveFooterProps {
  voucherId: VoucherFullData['id'];
}

function ActiveFooter(props: Readonly<ActiveFooterProps>) {
  const { voucherId } = props;

  const { data } = useGetVoucherData(voucherId);

  const classNames = styles();

  const { expire = '' } = data ?? {};

  return (
    <div className={classNames}>
      <Stack justify="space-around">
        <Stack align="center" spacing="3">
          <Icon color="white" size="sm">
            <CheckmarkCircleOutline />
          </Icon>
          <Text color="white">Ativado</Text>
        </Stack>
        <Text color="white">
          Válido até dia {formatter.formatDate(expire, 'DD [de] MMMM')}
        </Text>
      </Stack>
    </div>
  );
}

export default ActiveFooter;
