import { Heading, Stack } from '@backyard-ui/core';

import { type ModularContent } from '@/infra/services/modular-content/model';
import { Carousel } from '@/presenters/components/common/Carousel';
import { ContentCard } from '@/presenters/components/common/ContentCard';

export interface ContentsProps extends ModularContent.LastContents {}

function Contents(props: ContentsProps) {
  const { title, items } = props;

  return (
    <Stack direction="column" spacing={4}>
      <Heading size="2xl" asChild>
        <h2>{title}</h2>
      </Heading>

      <Carousel
        controls={{
          arrows: true,
        }}
        options={{
          slidesToScroll: 'auto',
          containScroll: 'trimSnaps',
          inViewThreshold: 1,
          dragFree: true,
        }}
      >
        {items.map((item) => (
          <ContentCard key={item.id} {...item} isInCarousel />
        ))}
      </Carousel>
    </Stack>
  );
}

export default Contents;
