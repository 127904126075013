import { Heading, Text } from '@backyard-ui/core';

import { useGlobalStore } from '@/presenters/store/global';

import {
  ActionButton,
  Footer,
  Grid,
  Header,
  Info,
  BackButton,
  ButtonGroup,
} from '../';
import { useGetVoucherData } from '../../../hooks/useGetVoucherData';
import { useSubmitVoucherShare } from '../../../hooks/useSubmitVoucherShare';
import { type VoucherFullData } from '../../../model';
import { formatter } from '../../../utils/formatter';
import { useVoucherModalStore } from '../../store';

interface ThirdStepProps {
  voucherId: VoucherFullData['id'];
}

function ThirdStep(props: Readonly<ThirdStepProps>) {
  const { voucherId } = props;

  const { data: voucherData } = useGetVoucherData(voucherId);

  const selectedCustomer = useVoucherModalStore(
    (state) => state.selectedCustomer
  );

  const loyaltyPoints = useGlobalStore(
    (state) => state.user.loyaltyProfile?.points.actual
  );

  const { shareVoucher, isLoading } = useSubmitVoucherShare({ id: voucherId });

  if (!voucherData) return null;

  return (
    <Grid voucherId={voucherId}>
      <Info>
        <div className="space-y-6">
          <Heading size="lg" color="gray-800" asChild>
            <h3>
              Tem certeza que deseja compartilhar esse cupom com{' '}
              {selectedCustomer?.customerName} por{' '}
              <span className="text-purple-500">
                {formatter.points(voucherData.price)} pts?
              </span>
            </h3>
          </Heading>

          <div className="space-y-4">
            <Text size="md" color="gray-500">
              Você gastará{' '}
              <strong>{formatter.points(voucherData.price)} pontos</strong> para
              enviar esse cupom, seu cliente poderá ver o cupom enviado na seção{' '}
              <strong>Minhas vantagens</strong>, e avisaremos que esse cupom foi
              enviado por você.
            </Text>
            <Text size="md" color="gray-500">
              Seu saldo atual é de{' '}
              <strong>{formatter.points(loyaltyPoints ?? 0)} pts</strong>.
            </Text>
          </div>
        </div>
      </Info>

      <Header voucherId={voucherId} subtitle="Cupom compartilhável" />

      <Footer voucherId={voucherId}>
        <ButtonGroup>
          <BackButton isDisabled={isLoading} />
          <ActionButton
            onClick={shareVoucher}
            isDisabled={!selectedCustomer}
            isLoading={isLoading}
          >
            {formatter.points(voucherData.price)} pts
          </ActionButton>
        </ButtonGroup>
      </Footer>
    </Grid>
  );
}

export default ThirdStep;
