'use client';

import { Stack, Skeleton } from '@backyard-ui/core';

import { Services } from '@/infra/services';
import { type ModularContent } from '@/infra/services/modular-content/model';
import { styles } from '@/presenters/components/common/ProductThumb/ProductThumb.styles';
import { useRecommendedProductsData } from '@/presenters/hooks/use-recommended-products-data';
import { useGlobalStore } from '@/presenters/store/global';
import { useModularContentStore } from '@/presenters/store/modular-content';

import {
  ProductsCarousel,
  type ProductsCarouselProps,
} from '../ProductsCarousel';

export interface RecommendedProductsCarouselProps {
  type: ModularContent.RecommendedProducts['type'];
  placement: string;
}

function RecommendedProductsCarousel(props: RecommendedProductsCarouselProps) {
  const { placement, type } = props;

  const userStore = useGlobalStore((state) => state.user);

  const {
    data: placementsData,
    isError: isPlacementsError,
    isLoading: isLoadingPlacements,
  } = useModularContentStore((state) => state.recommendations);

  const recommendation = placementsData?.[placement];

  const {
    data: productsData,
    isError: isProductsError,
    isLoading: isLoadingProducts,
  } = useRecommendedProductsData(recommendation, userStore.resources?.id);

  const handleProductClick: ProductsCarouselProps['onProductClick'] = async (
    event,
    product
  ) => {
    event.preventDefault();

    product.trackingUrl &&
      (await Services.RichRelevance.tracking({ url: product.trackingUrl }));

    window.location.href = product.url;
  };

  const hasError = isPlacementsError || isProductsError;
  const hasNoProducts = !isLoadingProducts && !productsData?.products.length;

  if (hasError || hasNoProducts) {
    return <></>;
  }

  if (isLoadingPlacements || isLoadingProducts) {
    return (
      <Stack
        direction="column"
        spacing={4}
        data-testid="recommended-products-loading"
      >
        <Skeleton width="250px" height="34px" />
        <Stack spacing={2} className="overflow-hidden">
          {Array.from({ length: 6 })
            .fill('')
            .map((_, index) => (
              <div
                key={index}
                className={styles().base({ className: 'shrink-0' })}
              >
                <Skeleton
                  width="100%"
                  height="100%"
                  UNSAFE_className="absolute inset-0"
                />
              </div>
            ))}
        </Stack>
      </Stack>
    );
  }

  return (
    <ProductsCarousel
      partnerName="algonomy"
      type={type}
      title={productsData?.title || ''}
      items={productsData?.products || []}
      onProductClick={handleProductClick}
    />
  );
}

export default RecommendedProductsCarousel;
