import { type ReactNode } from 'react';

import { Stack, Text } from '@backyard-ui/core';

import { Picture } from '../../../components';
import { useGetVoucherData } from '../../../hooks/useGetVoucherData';
import { type VoucherFullData } from '../../../model';

import { styles } from './Header.styles';

export interface HeaderProps {
  voucherId: VoucherFullData['id'];
  subtitle?: string;
  children?: ReactNode;
}

function Header(props: Readonly<HeaderProps>) {
  const { voucherId, subtitle, children } = props;

  const { data } = useGetVoucherData(props.voucherId);

  const className = styles({
    isActive: data?.isActive,
  });

  if (!data) return null;

  return (
    <div className={className.base()}>
      <Stack
        direction={{ initial: 'row', md: 'column' }}
        align="center"
        spacing={2}
      >
        <div className={className.picture()}>
          <Picture voucherId={voucherId} placement="modal" />
        </div>
        <div className={className.title()}>
          {subtitle && <Text color="current">{subtitle}</Text>}

          <Text size="xl" weight="bold" color="current" noOfLines={2}>
            {data.title}
          </Text>
        </div>
      </Stack>
      {children && <div className={className.content()}>{children}</div>}
    </div>
  );
}

export default Header;
