import { tv } from 'tailwind-variants';

export const styles = tv({
  base: `text-gray-400`,
  slots: {
    icon: `
      h-3
      w-3
      text-gray-400
    `,
  },
  variants: {
    isActive: {
      true: {
        base: `
          text-violet-200
        `,
        icon: `
          text-violet-200
        `,
      },
    },
  },
});
