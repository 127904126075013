import { Button, type ButtonProps } from '@backyard-ui/core';

import { useVoucherModalStore } from '../../store';

export interface BackButtonProps
  extends Omit<
    ButtonProps,
    'size' | 'appearance' | 'isFullWidth' | 'onClick' | 'children'
  > {}

function BackButton(props: BackButtonProps) {
  const { goToPreviousStep } = useVoucherModalStore();

  return (
    <Button
      size="md"
      variant="ghost"
      appearance="tertiary"
      onClick={goToPreviousStep}
      isFullWidth
      {...props}
    >
      Voltar
    </Button>
  );
}

export default BackButton;
