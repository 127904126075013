import { type ModularContent } from '@/infra/services/modular-content/model';
import { type ProductAds } from '@/infra/services/new-tail/model';

interface FilterProductsAdsByProductsProps {
  products: ModularContent.Product[];
  productsAds: ProductAds[];
}

const filterProductsAdsByProducts = (
  props: FilterProductsAdsByProductsProps
) => {
  const { products, productsAds } = props;

  return productsAds.filter(({ product_sku }) =>
    products.map(({ id }) => id).includes(Number(product_sku))
  );
};

export default filterProductsAdsByProducts;
