import { tv } from 'tailwind-variants';

export const styles = tv({
  slots: {
    base: `
      w-full
      md:px-6
    `,
    stepBullet: `
      flex
      h-4
      w-4
      items-center
      justify-center
      rounded-full
      bg-purple-100
      text-xs
      font-semibold
      text-purple-800
      md:h-6
      md:w-6
      md:text-md
      [&>*]:leading-none
    `,
    stepText: ``,
  },

  variants: {
    isActive: {
      true: {
        stepText: `
          text-white
        `,
      },
      false: {
        stepText: `
          text-gray-900
        `,
      },
    },
  },
});
