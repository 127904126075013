import { tv } from 'tailwind-variants';

export const styles = tv({
  slots: {
    section: ``,
    spinner: `mt-10`,
    observerRef: `h-1`,
  },
  variants: {
    hasBottomSpacing: {
      true: {
        section: `mb-8`,
      },
    },
    hasTopSpacing: {
      true: {
        section: `mt-8`,
      },
    },
  },
});
