import { type ModularContent } from '@/infra/services/modular-content/model';

type Product = ModularContent.Product;

export type Partner = 'algonomy' | 'elastic search' | 'retail media';

type ProductsListAttributesParams = {
  partnerName?: Partner;
  item: Product;
  title: string;
  position: number;
};

type ProductsListGtmDataAttributes = {
  'data-gtm-partner-name': Partner;
  'data-gtm-item-id': Product['id'];
  'data-gtm-item-list': string;
  'data-gtm-item-brand': string;
  'data-gtm-item-position': number;
  'data-gtm-item-name': string;
  'data-gtm-item-price'?: number;
  'data-gtm-item-express-delivery': Product['meta']['expressDeliveryDay'] | '';
  'data-gtm-item-category': string;
};

export const productsListGenerator = (
  params: ProductsListAttributesParams
): ProductsListGtmDataAttributes => {
  return {
    'data-gtm-partner-name': params.partnerName || 'elastic search',
    'data-gtm-item-id': params.item.id,
    'data-gtm-item-list': params.title,
    'data-gtm-item-brand': params.item.brand,
    'data-gtm-item-position': params.position,
    'data-gtm-item-name': params.item.shortName,
    'data-gtm-item-price': params.item.price.to,
    'data-gtm-item-express-delivery': params.item.meta.expressDeliveryDay ?? '',
    'data-gtm-item-category': params.item.categoryTree.replace(/\//g, '|'),
  };
};
